module.exports = {
  title: "引き換える",
  description: "各種バウチャーや無料宿泊などの特典をお楽しみください。",
  label_filter: "フィルター",
  label_point: "ポイント",
  label_sperator_point: "to",
  btn_label_view: " 検索 ",
  label_location: "会員特典ロケーション",
  label_category: "会員特典カテゴリー",
  label_point_required: "必要なポイント数:  ",
  label_point: " ポイント",
  placeholder_location: "すべての施設",
  placeholder_category: "全てのカテゴリー",
  crumb_home_label: "ホーム",
  crumb_reward_label: "ポイント交換",
  detail_point_required: "必要なポイント数",
  placeholder_quantity: "選択ください",
  label_btn_redeem_now: "ポイントを交換する",
  label_popup_title: "ポイントの交換を確定する",
  text_confirmation: "ポイントの交換をしますか？",
  placeholder_note: "このバウチャーをご利用の際には、お知らせ下さい。",
  label_btn_cancel: "取り消す",
  label_btn_ok: "引き換える",
  label_ribbon_special_offer: "スペシャルオファー",
  redemption_unavailable:
    'ポイントの引き換えは更新中であり、<span class="!tw-font-bold">2024年10月1日から6日</span>まで利用できません。',
};
